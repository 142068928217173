.uiprovider-container {
    height: 100%;
}

.uiprovider-form-large {
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 5%;
    padding: 20px;
    box-shadow: 5px 5px 15px 2px #c2bfbfcc;
    background-color: #FFFFFF;
    padding-bottom: 30px;
}

.uiprovider-form-small {
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    padding: 20px;
    box-shadow: 5px 5px 15px 2px #c2bfbfcc;
    background-color: #FFFFFF;
    padding-bottom: 30px;
}