.header-container{
    padding: 10px;
    height: 40px;
    background-color: rgb(224, 183, 15); /* For browsers that do not support gradients */
    /* background-image: linear-gradient(rgb(224, 183, 15), rgb(146, 136, 109)); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.title {
    display: flex;
    flex-direction: row;
}

.logo-text {
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bolder;
    color: #2a4d61;
}

.title:hover {
    cursor: pointer;
}

.header-right-container {
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}