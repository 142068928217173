.landing-page-container-small {
    padding-top: 60px;
    font-size: 12px;
    width: 100%;
}

.landing-page-container-large {
    margin-top: 60px;
    font-size: 16px;
    width: 100%;
}

.landing-page-content-large {
    text-align: center;
    font-weight: bold;
    font-size: larger;
    margin-top: 10%;
    padding: 20px;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 5px 5px 15px 2px #c2bfbfcc;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.landing-page-content-small {
    text-align: center;
    font-weight: bold;
    font-size: larger;
    margin-top: 10%;
    padding: 20px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 5px 5px 15px 2px #c2bfbfcc;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
