.data-grid-container {
    height: 100%;
    width: 100%;
    box-shadow: 5px 5px 15px 2px #c2bfbfcc;
    padding: 10px;
    background-color: #FFFFFF;
}

.data-grid {
    height: 78%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    border: 1px solid rgb(206, 205, 205);
    background-color: rgb(245, 245, 245);
}

.data-grid-row-headers {
}

.data-grid-rows {
}

.data-grid-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    max-height: 9%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    align-items: center;
}

.filters {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.data-grid-operations {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 60%;
}

.data-grid-footer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: auto;
    gap: 10px;
}

.row-count {
    height: auto;
    padding: 2px;
    padding-bottom: 4px;
    padding-right: 10px;
    text-align: right;
    font-size: small;
    background-color: rgb(221, 221, 221);
    color: black;
}

.save-btn-container {
    width: 100%;
    text-align: right;
}

.row {
    display: flex;
    flex-direction: row;
}

.column-header {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    color: black;
    font-weight: bold;
    font-size: small;
    text-align: center;
    background-color: rgb(75, 181, 224);
}

.cell-input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: small;
}

.cell-input:focus {
    background-color: rgb(221, 248, 255);
}

.select-input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
}

.select-input:focus {
    background-color: rgb(217, 239, 253);
}

.select-input-error {
    background-color: rgb(225, 170, 170);
}

.select-input-not-complete {
    background-color: rgb(243, 204, 158);
}

.select-input-row-selected {
    background-color: rgb(155, 205, 249);
}

.cell-error {
    background-color: rgb(225, 170, 170);
}

.cell-not-complete {
    background-color: rgb(243, 204, 158);
}

.row-number-container {
    height: 26px;
    width: 35px;
    border: solid 1px rgb(131, 130, 130);
}

.row-number {
    height: fill;
    width: fill;
    border: none;
    text-align: center;
    font-size: small;
    background-color: rgb(75, 181, 224);
    color: black;
    cursor: pointer;
}

.row-selected {
    background-color: rgb(155, 205, 249);
}