
.loading-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(224, 224, 224, 0.699);
    z-index: 2;
}

.overlay-content {
    margin-top: 20%;
    padding: 10px;
    text-align: center;
    z-index: 3;
}
